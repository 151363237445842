import Vue from 'vue'
import Vuex from 'vuex'

import * as api from '@/service/api'

import { QUESTION, STATE } from '@/utils/consts'

import i18n from './i18n'
import { sentryCustomMessage, sentryCaptureError } from '@/sentryInterface'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    response: null,
    state: STATE.LOADING,
    id: 0,
    name: 0,
    questions: null,
    pagination: 1,
    user: {
      firstname: '',
      lastname: '',
      title: ''
    },
    organization: {
      name: ''
    },
    token: '',
    isPreview: false,
    isAnonyme: true,
    scale_score: null,
    isShowCorrectAnswers: false,
    validity_minimum_score: null,
    errorMessage: null
  },
  getters: {
    getUser: state => {
      return state.user
    },
    getSurveyName: state => {
      return state.name
    },
    getIsPreview: state => {
      return state.isPreview
    },
    getOrganisation: state => {
      return state.organization
    },
    getIsShowCorrectAnswers: state => {
      return state.isShowCorrectAnswers
    },
    scale_score: state => {
      return state.scale_score
    },
    validity_minimum_score: state => {
      return state.validity_minimum_score
    },
    response: state => {
      return state.response
    },
    nbPage: state => {
      // if questions' survey is not defined return 0
      if (!state.questions) return 0

      const listQuestions = state.questions
      // if no questions return 0
      if (listQuestions.length === 0) return 0

      let nbPage = 0
      listQuestions.forEach(q => {
        if (q.page > nbPage) nbPage = q.page
      })
      return nbPage
    },
    questionsForPage: state => {
      // if questions' survey is not defined return []
      if (!state.questions) return []

      return state.questions.filter(q => q.page === state.pagination)
    },
    reorderQuestionsForPage: (_, getters) => {
      return getters.questionsForPage.sort(
        (a, b) => a.order_in_page - b.order_in_page
      )
    },
    reorderQuestionsGroupe: (_, getters) => {
      const result = []
      let corrupt_data = []
      getters.reorderQuestionsForPage.forEach(item => {
        if (!result[item.order_in_page]) {
          result[item.order_in_page] = []
        }
        //Checks if the item with an existing order_in_page belongs to a group.
        if (result[item.order_in_page].length > 0 && item.group_id !== null) {
          result[item.order_in_page].push(item)
        } else if (result[item.order_in_page].length > 0 && item.group_id === null) {
          corrupt_data.push([item])
          sentryCustomMessage('Problem: data corrupted, group_id null while order_in_page existing. Questionnaire is corrupt')
        } else {
          result[item.order_in_page].push(item)
        }
      })
      let resultAndCorruptData = result.concat(corrupt_data)
      return resultAndCorruptData.filter(el => el.length > 0)
    },
    requiredPageQuestionsAreFilled: (_, getters) => {
      return getters.questionsForPage.every(q => {
        if (q.mandatory) {
          return questionNotEmpty(q)
        } else {
          return true
        }
      })
    },
    countMandatoryQuestionsNotAnswered: (_, getters) => {
      let countMandatory = 0
      getters.questionsForPage.forEach(question => {
        if (question.mandatory && !questionNotEmpty(question)) {
          countMandatory++
        }
      })
      return countMandatory
    }
  },
  actions: {
    setState({ commit }, state) {
      commit('SET_STATE', state)
    },
    async loadData({ commit }, { isPreview, token }) {
      this.dispatch('setToken', token)
      this.dispatch('setIsPreview', isPreview)
      if (isPreview) {
        await this.dispatch('getSurveyPreview')
      } else {
        await this.dispatch('getSurvey')
      }
    },
    setToken({ commit }, token) {
      commit('SET_TOKEN', token)
    },
    setIsPreview({ commit }, isPreview) {
      commit('SET_PREVIEW', isPreview)
    },
    getSurveyPreview({ commit, state }) {
      return api
        .preview(state.token)
        .then(result => {
          if (result.language) {
            i18n.locale = result.language
            document.documentElement.setAttribute('lang', result.language)
            localStorage.setItem('lang', result.language)
          }
          commit('SET_PREVIEW', true)
          commit('SET_SURVEY', {
            survey: {
              id: result.id,
              name: result.name,
              questions: result.questions,
              isAnonyme: result.anonymous_response ? true : false
            },
            organization: result.organization
          })
        })
        .catch(e => {
          sentryCaptureError(e)
          this.dispatch('setApiError', e.response)
          return Promise.reject(e)
        })
    },

    getSurvey({ commit, state }) {
      return api
        .survey(state.token)
        .then(result => {
          if (result.survey.language) {
            i18n.locale = result.survey.language
            document.documentElement.setAttribute('lang', result.survey.language)
            localStorage.setItem('lang', result.survey.language)
          }
          commit('SET_SURVEY', {
            user: result.trainee ? result.trainee : result.trainer,
            survey: result.survey,
            organization: result.survey.organization,
            isAnonyme: !result.anonymous_response ? true : false
          })
        })
        .catch(e => {
          sentryCaptureError(e)
          this.dispatch('setApiError', e.response)
          return Promise.reject(e)
        })
    },
    setApiError({ commit }, errorResponse) {
      switch (errorResponse.data.status) {
        case 400:
          if (
            errorResponse.data.detail === 'This survey has already been filled'
          ) {
            commit('SET_ERROR_MESSAGE', i18n.t('error.surveyAlreadyFilled'))
          } else if (errorResponse.data.detail === 'This survey is disabled') {
            commit('SET_ERROR_MESSAGE', i18n.t('error.surveyDisabled'))
          } else if (errorResponse.data.detail === 'This survey has expired') {
            commit('SET_ERROR_MESSAGE', i18n.t('error.surveyExpired'))
          }
          commit('SET_STATE', STATE.ERROR)
          break
        case 404:
          commit('SET_ERROR_MESSAGE', null)
          commit('SET_STATE', STATE.NOT_FOUND)
          break
        default:
          commit('SET_ERROR_MESSAGE', null)
          commit('SET_STATE', STATE.ERROR)
          break
      }
    },
    setPage({ commit }, newPage) {
      commit('SET_PAGINATION', newPage)
    },
    udpateValueForQuestion({ commit }, { id, value }) {
      commit('UPDATE_VALUE', { id, value })
    },
    sendSurvey({ state, commit }) {
      const reformatedAnswers = state.questions.reduce((answers, question) => {
        if (!question.mandatory && !questionNotEmpty(question)) {
          return answers
        }
        answers[question.id] = question.value
        return answers
      }, {})
      return api
        .send(state.token, reformatedAnswers)
        .then(response => {
          if (!response) {
            commit('SET_STATE', STATE.FINISH)
          } else {
            commit('SET_RESPONSE', response)
            commit('SET_PAGINATION', 1)
            commit('SET_STATE', STATE.RESPONSE)
          }
        })
        .catch(e => {
          sentryCaptureError(e)
          return Promise.reject(e)
        })
    }
  },
  mutations: {
    SET_ERROR_MESSAGE(state, errorMessage) {
      state.errorMessage = errorMessage
    },
    SET_PREVIEW(state, isPreview) {
      state.isPreview = isPreview
    },
    SET_TOKEN(state, token) {
      state.token = token
    },
    SET_SURVEY(state, { survey, user, organization }) {
      state.user = user
      state.organization = organization
      state.id = survey.id
      state.isAnonyme = survey.isAnonyme
      state.isShowCorrectAnswers = survey.show_correct_answers
      state.validity_minimum_score = survey.validity_minimum_score
      state.scale_score = survey.scale_score

      state.name = survey.name
      state.questions = survey.questions.map(q => {
        switch (q.type) {
          case QUESTION.CHOICE_MULTIPLE:
            return Object.assign(q, { value: [] })
          case QUESTION.CHECKBOX:
            return Object.assign(q, { value: false })
          case QUESTION.CHOICE_RANGE:
          case QUESTION.CHOICE_DROPDOWN:
            return Object.assign(q, { value: null })
          default:
            return Object.assign(q, { value: '' })
        }
      })
    },
    SET_PAGINATION(state, newPage) {
      state.pagination = newPage
    },
    UPDATE_VALUE(state, { id, value }) {
      let question = state.questions.find(q => q.id === id)
      question.value = value
    },
    SET_STATE(state, newState) {
      state.state = newState
    },
    SET_RESPONSE(state, response) {
      state.response = response
    }
  }
})

function questionNotEmpty(question) {
  switch (question.type) {
    case QUESTION.CHOICE_MULTIPLE:
      return question.value.length > 0
    case QUESTION.CHECKBOX:
      return question.value
    case QUESTION.CHOICE_RANGE:
      return question.value !== null
    case QUESTION.TEXT_LONG:
    case QUESTION.TEXT_SHORT:
      return question.value !== ''
    default:
      return !!question.value
  }
}
